import { gql } from '@apollo/client'

import {
  manuscriptFragment,
  userFragment,
  fileFragment,
  teamMember,
  teamMemberWithIdentities,
  SuggestedJournalFragment,
} from './fragments'

// Temporary query for redirect
export const getSubmissionIds = gql`
  query getSubmissionIds($customId: String!) {
    getSubmissionIds(customId: $customId) {
      id
      submissionId
    }
  }
`
export const getDecisionLetter = gql`
  query getDecisionLetter($reviewId: ID!, $submissionId: ID!) {
    getDecisionLetter(reviewId: $reviewId, submissionId: $submissionId) {
      id
      content
    }
  }
`

export const getSubmission = gql`
  query getSubmission($submissionId: String!) {
    getSubmission(submissionId: $submissionId) {
      ...manuscriptDetails
    }
    getDraftRevision(submissionId: $submissionId) {
      ...manuscriptDetails
      comment {
        id
        type
        content
        files {
          ...manuscriptDetailsFile
        }
      }
    }
  }
  ${manuscriptFragment}
  ${fileFragment}
`

export const getSubmissionDiscount = gql`
  query getSubmissionDiscount($submissionId: String!) {
    getSubmissionDiscount(submissionId: $submissionId) {
      discountCode
      societyName
      societyDiscountCode
      societyMembershipId
    }
  }
`
export const getCurrentUser = gql`
  query {
    getCurrentUser {
      ...manuscriptDetailsUser
    }
  }
  ${userFragment}
`

export const getAllAcademicEditors = gql`
  query getAllAcademicEditors(
    $journalId: String
    $specialIssueId: String
    $sectionId: String
  ) {
    getAllAcademicEditors(
      journalId: $journalId
      specialIssueId: $specialIssueId
      sectionId: $sectionId
    ) {
      user {
        id
      }
    }
  }
`

export const getAcademicEditors = gql`
  query getAcademicEditors(
    $manuscriptId: String!
    $searchValue: String
    $requestedJournalEditorialBoard: Boolean
  ) {
    getAcademicEditors(
      manuscriptId: $manuscriptId
      searchValue: $searchValue
      requestedJournalEditorialBoard: $requestedJournalEditorialBoard
    ) {
      ...teamMemberWithIdentities
      workload
      score
    }
  }
  ${teamMemberWithIdentities}
`

export const getEditorThresholds = gql`
  query getEditorThresholds($submissionId: String!) {
    getEditorThresholds(submissionId: $submissionId) {
      editorThresholds {
        userId
        threshold
        handledVolume
      }
      totalHandledVolume
    }
  }
`

export const getPreviewEmail = gql`
  query getPreviewEmail($input: GetPreviewEmailInput!) {
    getPreviewEmail(input: $input) {
      html
      subject
    }
  }
`

export const getAcademicEditorsKeywords = gql`
  query getEditorsKeywords($submissionId: String!, $editorEmails: [String]!) {
    getEditorsKeywords(
      submissionId: $submissionId
      editorEmails: $editorEmails
    ) {
      email
      terms
    }
  }
`

export const getAllTriageEditors = gql`
  query getAllTriageEditors(
    $journalId: String
    $specialIssueId: String
    $sectionId: String
  ) {
    getAllTriageEditors(
      journalId: $journalId
      specialIssueId: $specialIssueId
      sectionId: $sectionId
    ) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const getTriageEditors = gql`
  query getTriageEditors($manuscriptId: String!) {
    getTriageEditors(manuscriptId: $manuscriptId) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const matchReviewerSuggestions = gql`
  query matchReviewerSuggestions($manuscriptId: String!) {
    matchReviewerSuggestions(manuscriptId: $manuscriptId) {
      userId
      suggestionId
    }
  }
`
export const loadSearchedReviewers = gql`
  query loadSearchedReviewers($input: LoadReviewerSuggestionsInput!) {
    loadSearchedReviewers(input: $input) {
      searchedReviewersFromPKG {
        id
        pkgId
        givenNames
        surname
        type
        aff
        email
        isInvited
        orcidId
        affRorId
        keywords
        previousReviewer
        completedReviews
        pendingInvitations
        currentlyReviewing
        conflictsOfInterest {
          id
          alias {
            aff
            country
            email
            name {
              surname
              givenNames
            }
          }
        }
      }
      searchedReviewersFromPhenomDatabase {
        id
        givenNames
        surname
        aff
        type
        pkgId
        email
        isInvited
        orcidId
        affRorId
        keywords
        previousReviewer
        completedReviews
        pendingInvitations
        currentlyReviewing
        conflictsOfInterest {
          id
          alias {
            aff
            country
            email
            name {
              surname
              givenNames
            }
          }
        }
      }
      totalNumberOfReviewersFromPKG
    }
  }
`

export const loadSearchedConcepts = gql`
  query loadSearchedConcepts($input: LoadSearchedConceptsInput!) {
    loadSearchedConcepts(input: $input) {
      name
      pkgId
    }
  }
`
export const getManuscriptConceptsByKeywords = gql`
  query manuscriptConceptsByKeywords($manuscriptId: String!) {
    manuscriptConceptsByKeywords(manuscriptId: $manuscriptId) {
      name
      pkgId
    }
  }
`

export const loadReviewerSuggestions = gql`
  query loadReviewerSuggestions(
    $manuscriptId: String!
    $suggestionsProvider: String!
  ) {
    loadReviewerSuggestions(
      manuscriptId: $manuscriptId
      suggestionsProvider: $suggestionsProvider
    ) {
      id
      email
      givenNames
      surname
      aff
      affRorId
      orcidId
      profileUrl
      numberOfReviews
      isInvited
      type
      score
      completedReviews
      pendingInvitations
      currentlyReviewing
      keywords
      previousReviewer
      hasPublicationsSaved
      conflictsOfInterest {
        id
        alias {
          aff
          country
          email
          name {
            surname
            givenNames
          }
        }
      }
    }
  }
`

export const loadReviewerPublications = gql`
  query loadReviewerPublications(
    $reviewerId: String!
    $manuscriptId: String!
    $reviewerPkgId: String
    $size: Int
    $offset: Int
    $reviewerSource: InvitationSource
  ) {
    loadReviewerPublications(
      reviewerId: $reviewerId
      reviewerPkgId: $reviewerPkgId
      manuscriptId: $manuscriptId
      size: $size
      offset: $offset
      reviewerSource: $reviewerSource
    ) {
      relatedPublications {
        title
        doi
        journalName
        authors
        publicationDate
      }
      otherPublications {
        title
        doi
        journalName
        authors
        publicationDate
      }
      retractions {
        title
        doi
        journalName
        authors
        publicationDate
        retractionReasons
      }
      totalNumberOfPublications
      hIndex
      citationCount
      publicationYearRange {
        fromYear
        toYear
      }
      publicationCountInLastYear
      lastPublicationDate
    }
  }
`
export const getReviewerSuggestionMetrics = gql`
  query getReviewerSuggestionMetrics(
    $reviewerId: String!
    $reviewerSource: InvitationSource!
  ) {
    getReviewerSuggestionMetrics(
      reviewerId: $reviewerId
      reviewerSource: $reviewerSource
    ) {
      numberOfInvitations
      numberOfAcceptances
      numberOfExpiredInvitations
      numberOfDeclinedInvitations
      numberOfCurrentlyReviewingManuscripts
      numberOfInvitationsLastSixMonths
      numberOfReviewedManuscripts
      averageReportSpeedSeconds
      latestReviewReportDate
      latestAcceptedInvitationDate
      firstReviewReportDate
      latestDeclinedInvitationDate
    }
  }
`

export const getReviewedManuscripts = gql`
  query getReviewedManuscripts($input: GetReviewedManuscriptsInput!) {
    getReviewedManuscripts(input: $input) {
      currentlyReviewing {
        title
        authors
        journalName
      }
      previousReviewed {
        previousReviewedManuscripts {
          title
          authors
          journalName
          publicationDate
        }
        totalNumberOfPreviousReviewed
      }

      reviewedManuscriptsOnOtherJournals {
        reviewedManuscripts {
          journalName
          count
        }
        totalNumberOfManuscripts
      }
    }
  }
`

export const isUserSubscribedToEmails = gql`
  query isUserSubscribedToEmails($userId: String) {
    isUserSubscribedToEmails(userId: $userId)
  }
`

export const isUnresponsiveOrOverdueReviewer = gql`
  query isUnresponsiveOrOverdueReviewer(
    $userId: String
    $suggestionId: String
    $email: String
    $manuscriptId: String!
  ) {
    isUnresponsiveOrOverdueReviewer(
      userId: $userId
      suggestionId: $suggestionId
      email: $email
      manuscriptId: $manuscriptId
    ) {
      isUnresponsive
      isOverdue
    }
  }
`

export const getConflictingAuthors = gql`
  query getConflictingAuthors(
    $manuscriptId: String!
    $rorId: String
    $aff: String
    $country: String
  ) {
    getConflictingAuthors(
      manuscriptId: $manuscriptId
      rorId: $rorId
      aff: $aff
      country: $country
    ) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const getSuggestedJournalsForTransfer = gql`
  query getSuggestedJournalsForTransfer($manuscriptId: String!) {
    getSuggestedJournalsForTransfer(manuscriptId: $manuscriptId) {
      journals {
        ...ReferralJournal
      }
      count
    }
  }
  ${SuggestedJournalFragment}
`

export const getSuggestedNetworkForTransferJournals = gql`
  query getSuggestedNetworkForTransferJournals($manuscriptId: String!) {
    getSuggestedNetworkForTransferJournals(manuscriptId: $manuscriptId) {
      journals {
        ...ReferralJournal
      }
      count
    }
  }
  ${SuggestedJournalFragment}
`

export const getSuggestedJournalsBySearch = gql`
  query getSuggestedJournalsBySearch(
    $input: GetSuggestedJournalsBySearchInput!
    $manuscriptId: String!
  ) {
    getSuggestedJournalsBySearch(input: $input, manuscriptId: $manuscriptId) {
      journals {
        ...ReferralJournal
      }
      count
    }
  }
  ${SuggestedJournalFragment}
`

export const getSuggestedJournalsBySearchCount = gql`
  query getSuggestedJournalsBySearchCount(
    $input: GetSuggestedJournalsBySearchInput!
    $manuscriptId: String!
  ) {
    getSuggestedJournalsBySearchCount(
      input: $input
      manuscriptId: $manuscriptId
    )
  }
`

export const getRejectReasons = gql`
  query getRejectReasons {
    getRejectReasons {
      rejectReason
      requireMoreInfo
      description
      allowTransfer
    }
  }
`

export const getScreeningReport = gql`
  query getScreeningReport($manuscriptId: String!) {
    getScreeningReport(manuscriptId: $manuscriptId) {
      turnitinSubmissionId
      totalSimilarityPercentage
      firstSourceSimilarityPercentage
      notes {
        content
        category
      }
      scopeCheckResult
      keyThemes {
        tag
        score
      }
      screenerGivenNames
      screenerSurname
      screenerEmail
    }
  }
`
export const getSimilarityViewerUrl = gql`
  query getSimilarityViewerUrl(
    $turnitinSubmissionId: String!
    $manuscriptId: String!
  ) {
    getSimilarityViewerUrl(
      turnitinSubmissionId: $turnitinSubmissionId
      manuscriptId: $manuscriptId
    )
  }
`

export const getReviewersReportDueDateExtensions = gql`
  query getReviewersReportDueDateExtensions($manuscriptId: String!) {
    getReviewersReportDueDateExtensions(manuscriptId: $manuscriptId) {
      reviewerTeamMemberId
      extendedWith
      extendedDate
    }
  }
`

export const getDecisionReasons = gql`
  query getDecisionReasons($decisionType: DecisionName!) {
    decisionReasons: getDecisionReasons(decisionType: $decisionType) {
      groupName
      extendedGroupName
      regularReasons {
        name
        extendedName
      }
      otherReasons {
        name
        extendedName
      }
    }
  }
`

export const getReviewersFromPreviousVersions = gql`
  query Query($manuscriptId: String!) {
    getReviewersFromPreviousVersions(manuscriptId: $manuscriptId) {
      id
      invitedDate
      responded
      name
      givenNames
      surname
      email
      aff
      invitationSource
      reviewerNumber
      currentlyReviewing
      pendingInvitations
      completedReviews
      orcidId
      isInvited
    }
  }
`
